import 'slick-carousel'
import $ from "jquery";

$('.infamous-critters-slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  asNavFor: '.infamous-critters-nav',
  adaptiveHeight: true,
});

$('.infamous-critters-nav').slick({
  slidesToShow: 5,
  slidesToScroll: 1,
  asNavFor: '.infamous-critters-slider',
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        variableWidth: true,
        arrows: false,
      },
    },
  ],
});
